/* *{
  font-family: 'Poppins', sans-serif !important;
} */
.Mui-selected{
  background-color: var(--main-light) !important;
  color:#ffff !important;
}
:root{
  --main-light:#3f50b5
}
/* width */
/* ::-webkit-scrollbar {
  width: 5px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
} */
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--main-light); 
}

/* ::-webkit-scrollbar-thumb:horizontal {
  background: var(--main-light); 
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover, ::-webkit-scrollbar-thumb:horizontal:hover  {
  background: rgb(38, 197, 189); 
} */
::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb:horizontal {
  background: var(--main-light); 
}



